<template>
	<div>
		<h3>Formas de pagamentos aceitas</h3>
		<p style="margin-bottom: 2em;">
			Selecione abaixo as formas de pagamentos aceitas pelo restaurante
		</p>
		<a-row>
			<a-alert
				v-if="error != ''"
				type="warning"
				:message="error"
				show-icon
				closable
				style="margin-bottom: 15px;"
				@close="error = ''"
			/>
		</a-row>
		<a-row type="flex" justify="start" :gutter="24">
			<a-col v-for="group in firstLineGroups" :key="group.id">
				<ToggleMethodsList :group="group" @click="handleMethodClick" />
			</a-col>
		</a-row>
		<a-row
			type="flex"
			justify="start"
			:gutter="[16, 16]"
			style="margin: 16px 0;"
		>
			<a-col v-for="group in secondLineGroups" :key="group.id">
				<ToggleMethodsList :group="group" @click="handleMethodClick" />
			</a-col>
		</a-row>
	</div>
</template>

<script>
import { ToggleMethodsList } from './components'
import { mapActions, mapGetters } from 'vuex'

export default {
	name: '',
	components: {
		ToggleMethodsList,
	},
	data() {
		return {
			groups: [],
			selectedMethods: [],
			error: '',
		}
	},
	computed: {
		...mapGetters({
			selectedMerchantId: `merchants/selectedMerchantId`,
		}),
		firstLineGroups: function () {
			// const codes = ['APP', 'MONEY']
			const codes = ['MONEY']
			const cols = this.groups.filter((group) => codes.includes(group.id))

			return [
				...cols,
				...this.groups.filter((group) => group.id === 'DEBIT_CARD'),
			]
		},
		secondLineGroups: function () {
			return this.groups.filter((group) => group.id === 'CREDIT_CARD')
		},
		selectedMethodsIds: {
			set: function (groups) {
				const availables = []

				groups.forEach((group) =>
					availables.push(
						...group.payment_methods
							.filter((method) => method.available)
							.map((method) => method.id)
					)
				)

				this.selectedMethods = availables
			},
			get: function () {
				return this.selectedMethods
			},
		},
	},
	async beforeMount() {
		await this.getData()
		this.selectedMethodsIds = this.groups
	},
	methods: {
		...mapActions('merchants', [
			'getPaymentMethods',
			'updatePaymentMethods',
		]),
		async getData() {
			const payload = {
				merchantId: this.selectedMerchantId,
				is_available: false,
			}

			this.groups = await this.getPaymentMethods(payload)
		},
		async handleMethodClick(values) {
			values.merchantId = this.selectedMerchantId

			const groupIdx = this.groups.findIndex(
				(group) => group.id === values.groupId
			)
			const methodIdx = this.groups[groupIdx].payment_methods.findIndex(
				(method) => method.id === values.methodId
			)

			this.groups[groupIdx].payment_methods[methodIdx].available =
				values.available
			this.selectedMethodsIds = this.groups

			if (this.selectedMethodsIds.length === 0) {
				this.error =
					'Você precisa informar, pelo menos, um método de pagamento.'

				this.groups[groupIdx].payment_methods[
					methodIdx
				].available = true
				this.selectedMethodsIds = this.groups
			} else {
				this.error = ''

				const payload = {
					merchantId: this.selectedMerchantId,
					data: this.selectedMethodsIds,
				}

				const res = await this.updatePaymentMethods(payload)
				this.selectedMethodsIds = res
			}
		},
	},
}
</script>

<style lang="less" scoped></style>
